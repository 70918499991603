/* eslint-disable @typescript-eslint/ban-ts-comment */
import BetaAuthenticate from "../components/BetaAuthenticate"
import { IntercomChat } from "../components/IntercomChat"
import "../styles/globals.css"
import { DotWave } from "@uiball/loaders"
import { DefaultSeo } from "next-seo"
import type { AppProps } from "next/app"
import Head from "next/head"
import { useRouter } from "next/router"
import Script from "next/script"
import React, { useEffect, useState } from "react"

function MyApp({ Component, pageProps }: AppProps) {
  const [isOwnerAuthenticated, setIsOwnerAuthenticated] = useState<
    boolean | null
  >(null)
  const router = useRouter()
  const canonicalUrl = (
    `https://www.coverdash.com` + (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0]

  // Temp client side password protect for every page
  useEffect(() => {
    // @ts-ignore
    ;(function (w, d, s, l, i) {
      // @ts-ignore
      w[l] = w[l] || []
      // @ts-ignore
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
      // @ts-ignore
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : ""
      // @ts-ignore
      j.async = true
      // @ts-ignore
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
      // @ts-ignore
      f.parentNode.insertBefore(j, f)
    })(window, document, "script", "dataLayer", "GTM-TR2P7SX")

    const urlParams = new URLSearchParams(window.location.search)
    const referrer = urlParams.get("ref")
    if (referrer) {
      localStorage.setItem("referrer", referrer)
    }

    setIsOwnerAuthenticated(
      localStorage.getItem("isOwnerAuthenticated") === "true"
    )
  }, [])

  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" &&
    isOwnerAuthenticated === null
  ) {
    return (
      <>
        <DefaultSeo
          title="Coverdash | Business Insurance Online Instantly"
          description="Coverdash helps make insurance accessible to businesses of all shapes and sizes. Get the coverage you need in a dash."
          canonical={canonicalUrl}
          openGraph={{
            url: "https://www.coverdash.com",
            site_name: "Coverdash | Business Insurance Online Instantly",
            title: "Coverdash | Business Insurance Online Instantly",
            description:
              "Coverdash helps make insurance accessible to businesses of all shapes and sizes. Get the coverage you need in a dash.",
            images: [
              {
                url: "https://coverdash.s3.amazonaws.com/logo/coverdash_social_preview_800x.jpg",
                width: 800,
                height: 418,
                alt: "Coverdash | Business Insurance Online Instantly",
              },
              {
                url: "https://coverdash.s3.amazonaws.com/logo/coverdash_social_preview_800x.jpg",
                width: 1200,
                height: 627,
                alt: "Coverdash | Business Insurance Online Instantly",
              },
            ],
          }}
          twitter={{
            handle: "@coverdash_inc",
            site: "@coverdash_inc",
            cardType: "summary_large_image",
          }}
        />
        <div className="cd-loader w-1/2 mx-auto my-20">
          <DotWave size={150} speed={1} color="#0ACF83" />
        </div>
      </>
    )
  }

  if (
    process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" &&
    !isOwnerAuthenticated
  ) {
    return (
      <>
        <DefaultSeo
          title="Coverdash | Business Insurance Online Instantly"
          description="Coverdash helps make insurance accessible to businesses of all shapes and sizes. Get the coverage you need in a dash."
          canonical={canonicalUrl}
          openGraph={{
            url: "https://www.coverdash.com",
            site_name: "Coverdash | Business Insurance Online Instantly",
            title: "Coverdash | Business Insurance Online Instantly",
            description:
              "Coverdash helps make insurance accessible to businesses of all shapes and sizes. Get the coverage you need in a dash.",
            images: [
              {
                url: "https://coverdash.s3.amazonaws.com/logo/coverdash_social_preview_800x.jpg",
                width: 800,
                height: 418,
                alt: "Coverdash | Business Insurance Online Instantly",
              },
              {
                url: "https://coverdash.s3.amazonaws.com/logo/coverdash_social_preview_800x.jpg",
                width: 1200,
                height: 627,
                alt: "Coverdash | Business Insurance Online Instantly",
              },
            ],
          }}
          twitter={{
            handle: "@coverdash_inc",
            site: "@coverdash_inc",
            cardType: "summary_large_image",
          }}
        />
        <IntercomChat />
        <BetaAuthenticate />
      </>
    )
  }

  return (
    <>
      <DefaultSeo
        title="Coverdash | Business Insurance Online Instantly"
        description="Coverdash helps make insurance accessible to businesses of all shapes and sizes. Get the coverage you need in a dash."
        canonical={canonicalUrl}
        openGraph={{
          url: "https://www.coverdash.com",
          site_name: "Coverdash | Business Insurance Online Instantly",
          title: "Coverdash | Business Insurance Online Instantly",
          description:
            "Coverdash helps make insurance accessible to businesses of all shapes and sizes. Get the coverage you need in a dash.",
          images: [
            {
              url: "https://coverdash.s3.amazonaws.com/logo/coverdash_social_preview_800x.jpg",
              width: 800,
              height: 418,
              alt: "Coverdash | Business Insurance Online Instantly",
            },
            {
              url: "https://coverdash.s3.amazonaws.com/logo/coverdash_social_preview_800x.jpg",
              width: 1200,
              height: 627,
              alt: "Coverdash | Business Insurance Online Instantly",
            },
          ],
        }}
        twitter={{
          handle: "@coverdash_inc",
          site: "@coverdash_inc",
          cardType: "summary_large_image",
        }}
      />
      {/* {showChat && (
        <Script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=62219b18-800f-4384-b866-b07a24a802aa"
        >
          {' '}
        </Script>
      )} */}
      {/* {showChat && (
        <>
          <Script id="intercom-settings">
            {`
window.intercomSettings = {
  api_base: "https://api-iam.intercom.io",
  app_id: "b4h57lim"
};
`}
          </Script>
          <Script id="intercom-snippet">
            {`
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/b4h57lim';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
          </Script>
        </>
      )} */}
      {process.env.NODE_ENV == "production" && (
        // Analytics Script
        <Script
          src="https://api.pirsch.io/pirsch.js"
          id="pirschjs"
          data-code="nm0aqykJXCMeVjSPGv4cCUfIkqCad52K"
          strategy="afterInteractive"
        />
      )}
      <Script
        src="https://fast.wistia.com/embed/medias/9br5u58m8c.jsonp"
        async
      ></Script>
      <Script
        src="https://fast.wistia.com/assets/external/E-v1.js"
        async
      ></Script>
      {/* <Script id="google-ads-snippet">
        {`
  window.addEventListener('load', function(){
    document.addEventListener('click',function(e){
      if(e.target.closest('a') && e.target.closest('a').innerText.includes('Get a quote')){
gtag('event', 'conversion', {'send_to': 'AW-11072507410/pZhkCImP7IgYEJKc5J8p'});
      }
    }); 
  });
`}
      </Script>
      <Script id="google-tags-snippet">
        {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'AW-11072507410');
`}
      </Script> */}
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
        <meta name="referrer" content="strict-origin"></meta>
        <link
          rel="shortcut icon"
          href="https://iamyqpnnibrsxhgaflmh.supabase.co/storage/v1/object/public/images-public/svg/coverdash_logo_shield_green.svg"
        />
        <script
          id="google-ads-snippet"
          dangerouslySetInnerHTML={{
            __html: `
         window.addEventListener('load', function(){
          document.addEventListener('click',function(e){
            if(e.target.closest('a') && e.target.closest('a').innerText.includes('Get a quote')){
      gtag('event', 'conversion', {'send_to': 'AW-11072507410/pZhkCImP7IgYEJKc5J8p'});
            }
          }); 
        });
         `,
          }}
        />
        <script
          id="google-tags-snippet"
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-11072507410');
          `,
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "Coverdash",
              url: "https://www.coverdash.com",
              logo: "https://coverdash.s3.amazonaws.com/logo/coverdash_logo_shield_green.png",
              sameAs: [
                "https://www.facebook.com/coverdash",
                "https://www.twitter.com/coverdash_inc",
                "https://www.instagram.com/coverdash_inc",
                "https://www.linkedin.com/company/coverdash",
              ],
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "About Us",
                  item: "https://www.coverdash.com/about",
                },
                {
                  "@type": "ListItem",
                  position: 2,
                  name: "Get a quote",
                  item: "https://www.coverdash.com/quote",
                },
                {
                  "@type": "ListItem",
                  position: 3,
                  name: "Small Business",
                  item: "https://www.coverdash.com/small-business",
                },
                {
                  "@type": "ListItem",
                  position: 4,
                  name: "Partner",
                  item: "https://www.coverdash.com/partner",
                },
                {
                  "@type": "ListItem",
                  position: 5,
                  name: "Blog",
                  item: "https://www.coverdash.com/blog",
                },
                {
                  "@type": "ListItem",
                  position: 6,
                  name: "Freelancer",
                  item: "https://www.coverdash.com/freelancer",
                },
                {
                  "@type": "ListItem",
                  position: 7,
                  name: "E-commerce",
                  item: "https://www.coverdash.com/e-commerce",
                },
                {
                  "@type": "ListItem",
                  position: 8,
                  name: "General Liability",
                  item: "https://www.coverdash.com/general-liability",
                },
                {
                  "@type": "ListItem",
                  position: 9,
                  name: "Business Owner's Policy",
                  item: "https://www.coverdash.com/business-policy",
                },
                {
                  "@type": "ListItem",
                  position: 10,
                  name: "Professional Liability",
                  item: "https://www.coverdash.com/professional-liability",
                },
                {
                  "@type": "ListItem",
                  position: 11,
                  name: "Workers Compensation",
                  item: "https://www.coverdash.com/workers-compensation",
                },
                {
                  "@type": "ListItem",
                  position: 12,
                  name: "Cyber Liability",
                  item: "https://www.coverdash.com/cyber",
                },
              ],
            }),
          }}
        />
      </Head>
      <div className="font-brand text-gray-900">
        <Component {...pageProps} />
      </div>
    </>
  )
}

export default MyApp
